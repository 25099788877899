<template>
  <div class="px-4" id="ticketCenter">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <a href="#" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </a>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/ticketcenter" class="text-sm font-medium text-gray-500 hover:text-gray-700">Ticketcenter</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Ticket Verkäufe</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Verkaufte Tickets</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">

        </div>
      </div>
    </div>
    <div>
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 pad-4">
          <div class="relative mb-4 rounded-md shadow-sm">
            <input @keyup="getAllSoldTickets" v-model="searchQuery" type="search" class="px-4 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Suche nach Tickets..." />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
          <div class="bg-white rounded shadow p-4" id="ticketResults">
            <TicketLoader v-if="loading"/>
            <div v-if="!loading">
              <article class="border-2 py-4 mb-2 bg-slate-50 rounded px-4 " v-for="titems in tickets" :key="titems.id">
                <div class="flex flex-row-reverse">
                    <button v-if="titems.status == 0" @click="changeState(titems.id, 3)" class="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Ticket entwerten
                    </button>
                    <button v-if="titems.status != 0" @click="changeState(titems.id, 0)" class="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Ticket erneuern
                    </button>
                </div>
                <div class="lg:flex lg:items-center lg:justify-between">
                  <div class="mr-4 flex-shrink-0">
                    <img class="h-16 w-auto rounded" :src="config.projectURL + 'assets/images/events/'+titems.image+'_thumb.jpg'">
                  </div>
                  <div class="min-w-0 flex-1">
                    <h2 style="margin-bottom: -4px;" class="text-l font-normal leading-7 text-gray-400 sm:truncate sm:text-l sm:tracking-tight">
                      {{ titems.title }}
                    </h2>
                    <h2 class="text-1xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
                      [#{{ titems.id }}] {{ titems.ticketTitle }}
                    </h2>
                    <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                      <div class="mt-2 flex items-center text-sm text-gray-500">
                        <BanknotesIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        {{ titems.price.toFixed(2) }}€
                      </div>
                      <div class="mt-2 flex items-center text-sm text-gray-500">
                        <TicketIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <p v-if="titems.status == 1 || titems.status == 4" class="text-green-700 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                          Gescannt ({{ renderTimestampForJS(titems.scanTimestamp) }})
                        </p>
                        <p v-if="titems.status == 0" class="text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                          Noch nicht gescannt
                        </p>
                        <p v-if="titems.status == 3" class="text-red-800 bg-red-50 ring-red-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                          Ticket wurde entwertet
                        </p>

                      </div>
                      <div class="mt-2 flex items-center text-sm text-gray-500">
                        <EnvelopeIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        {{ titems.email }}
                      </div>
                    </div>
                    <p class="mt-1 text-sm text-gray-400 font-thin">
                      <b class="font-bold">CheckoutID:</b> #{{ titems.checkoutSessionId }}<br>
                      <b class="font-bold">Käufer: </b>
                      <span v-if="titems.userId != null">
                        <router-link class="text-blue-400 font-bold" :to="'/customerInfo/'+titems.userId">{{ titems.firstname }} {{ titems.lastname }}</router-link>
                      </span>
                      <span v-else>
                        Gastbestellung
                      </span><br>
                      <b class="font-bold">Telefonnummer:</b>{{ titems.phone }}
                    </p>
                  </div>
                </div>
                <div class="mt-2 flex flex-row-reverse">
                  <span class="">
                    <button @click="download(titems.id)" type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      <ArrowDownTrayIcon class="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                      Ticket herunterladen
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="resendEmail(titems.id)" type="button" class="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                      <EnvelopeIcon class="-ml-0.5 mr-1.5 h-4 w-4" aria-hidden="true" />
                      Ticket erneut versenden
                    </button>
                  </span>
                </div>
              </article>
              <div v-if="!tickets.length" class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center">
                  <span class="bg-white px-2 text-sm text-gray-500">Keine passenden Ticketverkäufe gefunden</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notification class="my-4" v-if="sendTicket" type="success" title="Ticket versendet" message="Das Ticket wurder erfolgreich per E-Mail versendet."/>
  </div>

</template>

<script>
/* eslint-disable */

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData, searchArray} from "@/modules/main";
import TicketLoader from '@/components/loader/ticketLoader'
import { ChevronLeftIcon, ArrowDownTrayIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems,  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "TicketSales",
  title: "TicketSales",
  components: {
    TicketLoader,
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon, ArrowDownTrayIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon, BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems,  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, ExclamationTriangleIcon, XMarkIcon

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      convertStatTime: '',
      tickets: [],
      searchQuery: '',
      loading: true,
      sendTicket: false,
    }
  },
  mounted() {
    this.getAllSoldTickets()
  },
  methods: {
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsDate[1] + ' Uhr'
    },
    getAllSoldTickets(){
      this.loading = true
      aSyncData('ticketcenter/getAllSoldTickets', {organizerId: this.config.organizerId, searchQuery: this.searchQuery})
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              this.tickets = r.data
              this.loading = false
            }
          })
          .catch(e => {})
          .finally(() => {this.loading = false;})
    },
    resendEmail(ticketId){
      this.loading = true
      aSyncData('ticketcenter/resendEmail', {ticketId: ticketId})
          .then(r => {console.log(r)})
          .catch(e => {})
          .finally(() => {this.loading = false; this.sendTicket = true;})

    },
    changeState(id, state){
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('status', state)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/changeState', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.getAllSoldTickets()
            }
          })
    },
    download(ticketId){
      this.loading = true
      aSyncData('ticketcenter/download', {ticketId: ticketId}, {
        responseType: 'blob',
      })
          .then(r => {
            if(r.status === 250){
              const link = document.createElement('a')
              link.href = URL.createObjectURL(r.data)
              link.setAttribute('download', ticketId + '-ticket.pdf')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              URL.revokeObjectURL(link.href)
            }
          })
          .catch(e => {})
          .finally(() => {this.loading = false})
    }
  }
}
</script>

<style scoped>
#ticketCenter{
  margin-top: 16px;
}
#ticketCenter h1{
  font-size: 2em;
}
#ticketSearch{
  padding: 12px;
  border-radius: 10px;
  border: none;
  font-size: 1em;
  margin-bottom: 16px;
  width: 100%;
}
.ticket{
  margin-top: 16px;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 16px;
  transition: 0.3s;
}
.ticket:hover{
  background-color: rgba(0,0,0,0.02);
}
.ticket .ticketContent{
  padding: 8px 8px 8px 16px;
}
.ticket .ticketImage{
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: rgba(0,0,0,0.06);
  min-height: 80px;
}
.ticket .ticketContent h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 600;
  margin-top: 4px;
}
.ticket .ticketContent h5{
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketContent h6{
  font-size: 0.9em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketStats span{
  margin-bottom: 8px;
}
.field input,
.field textarea{
  display: block;
  width: calc(100% - 24px);
  max-width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
.field select{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
.label{
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 0.9em;
}
.label.scan{
  background-color: #9bf2d233;
  color: #67cba6;
}
.label.unscan{
  background-color: #2068ff33;
  color: #2068ff;
}
.label.red{
  background-color: #ff439833;
  color: #f21b7d;
}
</style>
<style>
#sendModal .vueModalInner{
  min-height: 100px!important;
}
#sendModal .t-center.pad-8{
  overflow-y: hidden!important;
}
</style>