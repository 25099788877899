<template>
  <article class="p-4 bg-slate-50 rounded mb-4">
    <div>
      <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mb-4">
        <div class="sm:col-span-2">
          <img class="rounded w-full" :src="config.projectURL + 'assets/images/events/'+eventItems.image+'.jpg'">
          <dl class="mt-2">
            <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow sm:px-6 sm:pt-6">
              <dt>
                <div class="absolute rounded-md bg-blue-600 p-3">
                  <TicketIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p class="ml-16 truncate text-sm font-medium text-gray-500">Verkaufte Tickets</p>
              </dt>
              <dd class="ml-16 flex items-baseline">
                <p class="text-2xl font-semibold text-gray-900">{{ eventItems.ticketCount }} / <span v-if="!eventItems.allQuantity">0</span>{{eventItems.allQuantity}}</p>
              </dd>
            </div>
          </dl>
          <dl class="mt-2">
            <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow sm:px-6 sm:pt-6">
              <dt>
                <div class="absolute rounded-md bg-blue-600 p-3">
                  <CurrencyEuroIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p class="ml-16 truncate text-sm font-medium text-gray-500">Ticketumsatz</p>
              </dt>
              <dd class="ml-16 flex items-baseline">
                <p v-if="eventItems.ticketUmsatz" class="text-2xl font-semibold text-gray-900" >{{ parseInt(eventItems.ticketUmsatz).toFixed(2)}}€</p>
                <p v-else class="text-2xl font-semibold text-gray-900">Noch keinen Umsatz</p>
              </dd>
            </div>
          </dl>
        </div>
        <div class="sm:col-span-4">
          <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div class="ml-4 mt-2">
                <h4 class="font-bold">
                  <span class="inline-flex items-center rounded bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">{{ eventItems.categories }}</span>
                  {{ eventItems.title }}
                </h4>
                <h5 class="text-gray-500">{{ eventItems.subTitle }}</h5>
              </div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <span class="isolate inline-flex -space-x-px rounded-md shadow-sm">
              <button title="Exporte"  @click="exportModal = true;" type="button" class="cursor-pointer relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                <span class="sr-only">Exporte</span>
                <ArrowDownTrayIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button title="Duplizieren" @click="copy" type="button" class="cursor-pointer relative inline-flex items-center bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                <span class="sr-only">Duplizieren</span>
                <DocumentDuplicateIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button title="Bearbeiten" @click="$router.push({name: 'EventEdit', params: { eventId: eventItems.id }});" type="button" class="cursor-pointer relative inline-flex items-center rounded-r-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                <span class="sr-only">Bearbeiten</span>
                <PencilSquareIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
              </div>
            </div>
            <ul role="list" class="mt-8">
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <ClockIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">Eventzeitraum</a>
                    <p class="text-gray-500">{{ renderTimestampForJS(eventItems.startTime) }} Uhr - {{ renderTimestampForJS(eventItems.endTime) }} Uhr</p>
                  </div>
                </div>
              </li>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <MapPinIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">Location</a>
                    <p class="text-gray-500">{{eventItems.location }}</p>
                  </div>
                </div>
              </li>
              <li class="col-span-1 flex rounded-md shadow-sm mt-3">
                <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                  <QrCodeIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                  <div class="px-4 py-2 text-sm">
                    <a class="font-bold text-gray-900 hover:text-gray-600">Gescannte Tickets</a>
                    <p class="text-gray-500">{{ eventItems.scannedTickets }} von {{eventItems.ticketCount }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <TransitionRoot as="template" :show="exportModal">
      <Dialog as="div" class="relative z-10" @close="exportModal = false;">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit.prevent="exportModal = false;">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="exportModal = false;">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ArrowDownTrayIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 mb-4">Exporte</DialogTitle>
                      <div class="mb-4">
                        <alert type="info" title="Hinweis" message="Die genannten Gebühren in den Exporten beziehen sich lediglich auf die Ticketgebühren und nicht auf die Gebühren des Zahlungsdienstleisters." />
                      </div>
                      <button type="button" @click="getSaleExportData();" class="w-full inline-flex items-center gap-x-1.5 rounded-md bg-slate-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
                        <ArrowDownTrayIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        Ticketverkäufe Zusammenfassung
                      </button>
                      <button type="button" @click="getCheckoutsExportData();" class="mt-4 w-full inline-flex items-center gap-x-1.5 rounded-md bg-slate-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
                        <ArrowDownTrayIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        Checkouts einzeln
                      </button>
                      <button type="button" @click="getSingleTicketsExportData();" class="mt-4 w-full inline-flex items-center gap-x-1.5 rounded-md bg-slate-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
                        <ArrowDownTrayIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        Tickets einzeln
                      </button>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Erledigt</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

  </article>
</template>

<script>
/* eslint-disable */
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import { ArrowDownTrayIcon, PhotoIcon, PencilSquareIcon, DocumentDuplicateIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon, MapPinIcon, ClockIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import alert from "@/components/alerts/alert";

export default {
  name: "eventItems",
  props: ["eventItems"],
  emits: ['reload'],
  components:{
    ArrowDownTrayIcon, PhotoIcon, PencilSquareIcon, DocumentDuplicateIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon, MapPinIcon, ClockIcon, XMarkIcon,
    RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    alert
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      timestamp: this.eventItems.timestamp,
      coverttime: '',
      eventid: '',
      hover: false,
      exportModal: false,
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    copy(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.eventItems.id)
      this.axios.post('/events/copy', fD)
          .then(r =>{
            if(r.status === 250){
              _this.$emit('duplicate')
            }
          })
          .catch(e => {})
    },
    getCheckoutsExportData(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.eventItems.id)
      this.axios.post('/events/getCheckoutsExportData', fD)
          .then(r =>{
            if(r.status === 250){
              _this.download('Checkout-Id,Vorname,Nachname,E-Mail,Telefonnummer,Ticketart,Kaufpreis p. Ticket,Anzahl,Summe ohne Gebühren, Gebühren, Gesamtsumme\r\n',  r.data, 'Einzelne-Checkouts')
            }
          })
          .catch(e => {})
    },
    getSaleExportData(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.eventItems.id)
      this.axios.post('/events/getSaleExportData', fD)
          .then(r =>{
            if(r.status === 250){
              _this.download('Ticketart,Einzelpreis (ohne Gebühr),Anzahl Verkäufe, Gesamtumsatz o. Gebühr, Gebühr insgesamt, Gesamtumsatz\r\n', r.data, 'Verläufe-Zusammenfassung')
            }
          })
          .catch(e => {})
    },
    getSingleTicketsExportData(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.eventItems.id)
      this.axios.post('/events/getSingleTicketsExportData', fD)
          .then(r =>{
            if(r.status === 250){
              _this.download('TicketId,CheckoutId, E-Mail-Adresse, Kaufpreis (ohne Gebühr), Ticketart, Scan-Status, Scan-Zeitstempel, Erstellungs-Zeitstempel\r\n', r.data, 'tickets')
            }
          })
          .catch(e => {})
    },
    download(csvHead, csvdata, filename){
      let csv = csvHead

      for(let row = 0; row < csvdata.length; row++){
        let keysAmount = Object.keys(csvdata[row]).length
        let keysCounter = 0

        for(let key in csvdata[row]){
          csv += csvdata[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
          keysCounter++
        }

        keysCounter = 0
      }

      let link = document.createElement("a")
      link.setAttribute("href", 'data:text/plain;charset=utf-8,' + encodeURI(csv));
      link.setAttribute("download", "EventExport-"+filename+".csv");

      link.click();
    },

    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsTime[1]
    },
  }
}
</script>

<style scoped>

</style>